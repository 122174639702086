
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import Component from 'vue-class-component'
import SystemParameter from '@/models/SystemParameter'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { Ref, Watch } from 'vue-property-decorator'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import User from '@/models/User'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import { integerMask, currencyMask, percentageMask } from '@/models/interface/Masks'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import moment from 'moment'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import WebMessage from '@/models/WebMessage'
import EventTracker from '@/components/EventTracker/EventTracker.vue'
import ManageActionsBtns from './Components/ManageActionsBtns.vue'

Component.registerHooks(['beforeRouteLeave'])
@Component({
  components: {
    Widget,
    FormWizard,
    TabContent,
    UserPicker,
    IconAction,
    DatePicker,
    ManageActionsBtns,
    FormInput,
    CheckboxInput,
    SelectPicker,
    FooterNav,
    EventTracker,
  },
})
export default class SystemParamsHome extends ViewModel {
  @Ref() readonly formWizard!: any

  public creating_fields: any = {
    section: null,
    type: null,
  }

  public busy: boolean = true

  public laoding: boolean = false

  public new_parameter: boolean = false

  public system_params: any = []

  public editing: any = []

  public loaded_data: any = {}

  public current_tab: any = 0

  public temp_system_param: any = {
    section: '',
    value_type: '',
    key: '',
    type: '',
    key_value: '',
  }

  public group_of_list_types: any = {
    section_list: [],
    type_list: [],
  }

  public get loadingStates() {
    let entries = Object.entries(this.loaded_data)
    entries.forEach((entry: any) => {
      if (entry[1] && entry[1].includes('loading')) {
        entry[1] = false
      }
    })
    return entries
  }

  public value_type_mapped(val: any) {
    let map: any = {
      percent: 'number',
      currency: 'number',
      integer: 'number',
      float: 'number',
      url: 'text',
      email: 'email',
      password: 'password',
      string: 'text',
    }

    if (map[val]) {
      return map[val]
    }

    return 'text'
  }

  public value_type: any = [
    { name: 'Percent', value: 'percent' },
    { name: 'Currency', value: 'currency' },
    { name: 'Password', value: 'password' },
    { name: 'Integer', value: 'integer' },
    { name: 'Float', value: 'float' },
    { name: 'Email', value: 'email' },
    { name: 'Url', value: 'url' },
    { name: 'Text', value: 'string' },
  ]

  public input_type: any = [
    {
      name: 'User Picker',
      value: 'user_picker',
    },
    {
      name: 'Date Picker',
      value: 'date_picker',
    },
    {
      name: 'DateTime Picker',
      value: 'datetime_picker',
    },
    {
      name: 'Switch',
      value: 'checkbox',
    },
    {
      name: 'Input',
      value: 'form_input',
    },
    {
      name: 'Event Tracker',
      value: 'event_tracker',
    },
  ]

  public get is_dirty(): boolean {
    if (!this.system_params.length) return false
    return this.dataExtractor(this.system_params).some(p => p.is_dirty)
  }

  public get maskList() {
    return { integerMask, currencyMask, percentageMask }
  }

  public beforeRouteLeave(to: object, from: object, next: any) {
    if (!this.is_dirty) {
      next()
    } else {
      WebMessage.warning('You have unsaved changes, save before leaving the page.')
    }
  }

  public mounted() {
    this.loadAll()
  }

  public async loadAll() {
    return SystemParameter.loadAll().then((response: any) => {
      let grouped = this.groupBySectionsAndTypes(response)
      this.system_params = grouped
      setTimeout(() => {
        this.loadTabsSections(response)
        this.busy = false
        this.loading = false
      }, 1000)
    })
  }

  public loadTabsSections(params: any) {
    this.group_of_list_types.section_list = this.system_params.map((param: any) => ({
      name: this.clearName(param[0]),
      value: param[0],
    }))

    let temp_list = params.map((param: any) => param.type)

    const uniqueValues = Array.from(new Set(temp_list))
    // console.log('uniqueValues', uniqueValues)
    this.group_of_list_types.type_list = uniqueValues.map((param: any) => ({
      name: this.clearName(param),
      value: param,
    }))
  }

  public groupBySectionsAndTypes(paramsArray: any) {
    let groups: any = {}
    for (let param of paramsArray) {
      param = Object.assign(new SystemParameter(), param)
      param.updateChecksum()
      if (!groups[param.section]) {
        groups[param.section] = {}
      }
      if (groups[param.section].hasOwnProperty(param.type)) {
        groups[param.section][param.type].push(param)
      } else {
        groups[param.section][param.type] = [param]
      }
    }

    return Object.entries(groups)
  }

  public clearName(name: any) {
    return name.replaceAll('_', ' ')
  }

  public capitalize(name: any) {
    // @ts-ignore
    return this.$options.filters.capitalize(this.clearName(name))
  }

  public generateId(name: any) {
    return name.replaceAll('_', '-')
  }

  public select(s) {
    this.editing.push(s)
  }

  @Watch('temp_system_param.input_type')
  public updateValueType() {
    if (
      this.temp_system_param.input_type === 'event_tracker'
      && !Array.isArray(this.temp_system_param.key_value)
    ) {
      this.temp_system_param.key_value = []
      this.temp_system_param.value_type = 'json'
    } else if (this.temp_system_param.input_type !== 'event_tracker') {
      this.temp_system_param.key_value = ''
      this.temp_system_param.value_type = 'string'
    }
  }

  /**
   * This is to remove the input from the edition state
   *
   * Remove Selected Field from Edition
   */
  public removeSelection(section: any, force: boolean = false, event: any = null) {
    if (
      this.loaded_data[section.id]
      && event
      && this.loaded_data[section.id].value !== event.value
    ) {
      // When this condition is true, it means that the user has changed the value of a loaded data
      this.loaded_data[section.id] = {
        name: event.name,
        value: event.value,
      }
      // @ts-ignore
      if (this.$refs[`load-${section.id}`].length) {
        // @ts-ignore
        this.$refs[`load-${section.id}`][0].innerText = event.name
      }
    }
    const exec = () => (this.editing = this.editing.filter((item: any) => item !== section.key))
    if (force) exec()
    else {
      setTimeout(() => {
        exec()
      }, 500)
    }
  }

  public searchUser(id: any, param_id: any) {
    this.loading = true
    User.searchOptions({
      value: id,
    }).then(response => {
      this.loaded_data[param_id].name = response[0]?.name || '-'
      // @ts-ignore
      if (this.$refs[`load-${param_id}`].length) {
        // @ts-ignore
        this.$refs[`load-${param_id}`][0].innerText = response[0]?.name || '-'
      }
      this.loading = false
    })
  }

  /**
   * Check the data so it can be formatted
   *
   * @param id
   */
  public checkDataToFormat(section: any) {
    if (section.input_type === 'user_picker') {
      if (!this.loaded_data[section.id]) {
        this.loaded_data[section.id] = {
          value: section.key_value,
          name: 'loading...',
          param_id: section.id,
        }
        setTimeout(() => {
          this.searchUser(section.key_value, section.id)
        }, 1000)
      }
      if (this.loaded_data[section.id] && !this.loaded_data[section.id].name.includes('loading')) {
        if (this.loaded_data[section.id].value !== section.key_value) {
          delete this.loaded_data[section.id]
          this.checkDataToFormat(section)
        }
      }
      return this.loaded_data[section.id].name
    }

    return this.formatByValueType(section)
  }

  public formatByValueType(section: any) {
    if (section.value_type === 'percent') {
      return `${section.key_value}%`
    }

    if (section.value_type === 'boolean') {
      return section.key_value ? 'Yes' : 'No'
    }

    if (section.value_type === 'date') {
      return moment(section.key_value).format('MM/DD/YYYY')
    }
    return section.key_value
  }

  public checkMasks(section: any) {
    if (section.value_type === 'currency') {
      return this.maskList.currencyMask
    }
    if (section.value_type === 'percent') {
      return this.maskList.percentageMask
    }
  }

  public newParam() {
    this.new_parameter = true
  }

  public formatKey(key: any = null) {
    if (key !== null) {
      this.temp_system_param[key] = this.temp_system_param[key].toLowerCase().replace(/ /g, '_')
    } else {
      this.temp_system_param.key = this.temp_system_param.key.toLowerCase().replace(/ /g, '_')
    }
  }

  public addNewParameter() {
    this.loading = true
    // validate
    if (this.temp_system_param.input_type === 'user_picker') {
      this.temp_system_param.value_type = 'string'
    }
    if (this.temp_system_param.input_type === 'date_picker') {
      this.temp_system_param.value_type = 'date'
    }
    if (this.temp_system_param.input_type === 'checkbox') {
      this.temp_system_param.value_type = 'boolean'
    }

    let system_param: SystemParameter = Object.assign(new SystemParameter(), this.temp_system_param)

    system_param.save().then(response => {
      this.loading = false
      setTimeout(() => {
        this.loadAll()
        this.loading = false
        this.clearTemp()
      }, 1500)
      // console.log('created system param', response)
    })

    // console.log('the new param props', Object.assign(new SystemParameter(), this.temp_system_param))
  }

  public nextTab() {
    if (this.current_tab + 1 <= this.system_params.length) {
      this.current_tab++
    }
  }

  public prevTab() {
    if (this.current_tab - 1 >= 0) {
      this.current_tab--
    }
  }

  public checkForUnsavedChanges(newTabIndex: any, oldTabIndex: any, event: any) {
    if (this.is_dirty) {
      event.preventDefault()
      WebMessage.warning('You have unsaved changes. Please save them before changing tabs.')
      return
    }

    this.editing = []
  }

  public dataExtractor(data: any) {
    let acc: any = []
    Object.values(data[this.current_tab][1]).forEach((param: any) => {
      Object.values(param).forEach((prm: any) => {
        acc.push(prm)
      })
    })

    return acc
  }

  public saveChanges() {
    let res
    this.loading = true
    let visable_parameters: any = this.dataExtractor(this.system_params)

    let changed_params = visable_parameters.filter(
      (parameter: SystemParameter) => parameter.is_dirty,
    )

    if (changed_params.length === 1) {
      res = changed_params[0].save()
    }
    if (changed_params.length > 1) {
      res = SystemParameter.multipleUpdate(changed_params)
    }

    res.then(() => {
      setTimeout(() => {
        this.loadAll()
        this.loading = false
        this.clearTemp()
      }, 3000)
    })
  }

  public clearTemp() {
    this.creating_fields = {
      section: false,
      type: false,
    }

    this.temp_system_param = {
      section: '',
      value_type: '',
      key: '',
      type: '',
      key_value: '',
    }
  }
}

import { getModule } from 'vuex-module-decorators'
// @ts-ignore
import { Md5 } from 'ts-md5/dist/md5'
import SystemtModule from '@/store/SystemModule'
import Api from './Api'

import Model from './interface/Model'

const api = new Api()

export default class SystemParameter extends Model {
  public name = 'SystemParameter'

  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'system_parameters' as string | null,
      plural: 'system_parameters' as string | null,
    },
  }

  public id: string | null = null;

  public section: string | null = null;

  public type: string | null = null;

  public key: string | null = null;

  public value_type: string | null = null;

  public key_value: any = '';

  public protected: boolean = false;

  public secure: boolean = false;

  public created_at: string | null = null;

  public input_type: string | null = null;

  public _hash: string | Int32Array = ''

  public static loadAll() {
    return api.get('system_parameters').then((response: any) => response.data.result)
  }

  public get apiData(): any {
    return {
      id: this.id,
      section: this.section,
      type: this.type,
      key: this.key,
      value_type: this.value_type,
      key_value: this.key_value,
      protected: this.protected,
      secure: this.secure,
      created_at: this.created_at,
      input_type: this.input_type,
    }
  }

  public get is_dirty(): boolean {
    return this._hash !== this.calculateChecksum()
  }

  private calculateChecksum(): string | Int32Array {
    return Md5.hashStr(JSON.stringify(this.apiData))
  }

  public updateChecksum() {
    this._hash = this.calculateChecksum()
  }

  public static multipleUpdate(parameters: any) {
    const instance_id = getModule(SystemtModule)._uuid
    return api.post('system_parameters_multiple', { parameters, instance_id })
  }
}
